<template>
  <div class="background">
    <div class="container">
      <el-tabs tab-position="left">
        <el-tab-pane v-for="(item, index) in ruleData" :key="`rule_detail_${index}`">
          <div slot="label">
            <div class="label-first-layer">
              <div class="label-second-layer">
                <div class="label-third-layer">
                  <div class="label-text">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <rule-detail
            :name="item.name"
            :ruleDetail="item.detail"
            :gameContent="item.content"
            :type="item.type"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ruleDetail from '@/components/ruleDetail'

export default {
  name: 'Rule',
  components: { ruleDetail },
  data() {
    return {
      ruleData: [
        {
          name: 'XXXX',
          detail: 'XXXXX \n XXXXXX'
        }
      ]
    }
  },

  methods: {
  }
}
</script>

<style scoped>
.background {
  min-height: 100vh;
  width: 100%;
  background-image: url('../../assets/images/background_2025_2.jpeg');
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center top;
  margin: 0px;
  padding: 0px 0px 20px 0px;
}

.container {
  width: calc(100% - 120px);
  padding: 200px 0px 20px 0px;
}

.label-first-layer {
  margin: 0px auto;
  border: 4px solid #FFD86D;
  padding: 3px;
}

.label-second-layer {
  border: 1px solid #FFD86D;
  padding: 2px;
}

.label-third-layer {
  background-color: #D92411;
  line-height: 20px;
}

.label-text {
  width: 85px;
  font-size: 14px;
  font-weight: 400;
  color: #FFD86D;
  text-align: center;
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>
