<template>
  <div class="table-drawer">
    <div class="table-container">
      <el-form label-width="120px">
        <!-- 一号桌 -->
        <el-form-item>
          <span slot="label" class="table-name-label">一号桌</span>

          <div class="table-name-box">
            <div v-for="(item, index) in tablePerson.first" :key="index" class="table-name-item">
              <div class="table-name-item-inner">
                <span v-if="item.visible" class="table-name-item-text">{{ item.name }}</span>
                <span v-else class="table-name-item-text">
                  {{ `${index + 1}号` }}
                  <i class="el-icon-view" @click="tablePerson.first[index].visible = !tablePerson.first[index].visible" />
                </span>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- 二号桌 -->
        <el-form-item>
          <span slot="label" class="table-name-label">二号桌</span>

          <div class="table-name-box">
            <div v-for="(item, index) in tablePerson.second" :key="index" class="table-name-item">
              <div class="table-name-item-inner">
                <span v-if="item.visible" class="table-name-item-text">{{ item.name }}</span>
                <span v-else class="table-name-item-text">
                  {{ `${index + 1}号` }}
                  <i class="el-icon-view" @click="tablePerson.second[index].visible = !tablePerson.second[index].visible" />
                </span>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- 三号桌 -->
        <el-form-item>
          <span slot="label" class="table-name-label">三号桌</span>

          <div class="table-name-box">
            <div v-for="(item, index) in tablePerson.third" :key="index" class="table-name-item">
              <div class="table-name-item-inner">
                <span v-if="item.visible" class="table-name-item-text">{{ item.name }}</span>
                <span v-else class="table-name-item-text">
                  {{ `${index + 1}号` }}
                  <i class="el-icon-view" @click="tablePerson.third[index].visible = !tablePerson.third[index].visible" />
                </span>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- 四号桌 -->
        <el-form-item>
          <span slot="label" class="table-name-label">四号桌</span>

          <div class="table-name-box">
            <div v-for="(item, index) in tablePerson.fourth" :key="index" class="table-name-item">
              <div class="table-name-item-inner">
                <span v-if="item.visible" class="table-name-item-text">{{ item.name }}</span>
                <span v-else class="table-name-item-text">
                  {{ `${index + 1}号` }}
                  <i class="el-icon-view" @click="tablePerson.fourth[index].visible = !tablePerson.fourth[index].visible" />
                </span>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- 五号桌 -->
        <el-form-item>
          <span slot="label" class="table-name-label">五号桌</span>

          <div class="table-name-box">
            <div v-for="(item, index) in tablePerson.fifth" :key="index" class="table-name-item">
              <div class="table-name-item-inner">
                <span v-if="item.visible" class="table-name-item-text">{{ item.name }}</span>
                <span v-else class="table-name-item-text">
                  {{ `${index + 1}号` }}
                  <i class="el-icon-view" @click="tablePerson.fifth[index].visible = !tablePerson.fifth[index].visible" />
                </span>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TablePersonDetail',

  data() {
    return {
      tablePerson: {
        first: [
          { name: 'XXXXXXX', visible: false },
        ],
        second: [
          { name: 'XXXXXXX', visible: false },
        ],
        third: [
          { name: 'XXXXXXX', visible: false },
        ],
        fourth: [
          { name: 'XXXXXXX', visible: false },
        ],
        fifth: [
          { name: 'XXXXXXX', visible: false },
        ],
      },
    }
  },
}
</script>

<style scoped>
.table-drawer {
  height: 100%;
  width: 100%;
  background-image: url('../../assets/images/table_person_bg.jpeg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 20px;
}

.table-container {
  border: 5px solid #FFE58D;
  height: calc(100% - 40px);
  padding: 16px;
  border-radius: 20px;
}

.table-name-label {
  color: #FFE58D;
  font-size: 30px;
  font-weight: 600;
}

.table-name-box {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  overflow: scroll;
  margin: 0px 0px;
  padding: 0px 4px;
  gap: 8px;
}

.table-name-item {
  font-family: serif;
  border: 3px solid #FFE58D;
  width: 120px;
  text-align: center;
  color: #FFE58D;
  padding: 3px;
}

.table-name-item-inner {
  border: 2px solid #FFE58D;
}

.table-name-item-text {
  font-size: 28px;
  font-weight: 600;
}
</style>
